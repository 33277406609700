<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <b-form-input
              size="sm"
              :aria-label="$t('COMMON.NAME')"
              :placeholder="$t('COMMON.NAME')"
              v-model="filterName"
              trim
            ></b-form-input>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Search"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="powerlines"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="showTableOverlay"
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            powerlines.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>
    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import { 
  POWERLINE_LIST_REQUEST, 
  POWERLINE_SET_CURRENT, 
  POWERLINE_CLEAR_CURRENT 
} from "@/core/services/store/powerline.module";

export default {
  name: "powerlines",
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          sortingKey: "id"
        },
        {
          key: "name",
          label: this.$t("COMMON.NAME"),
          sortable: true,
          sortingKey: "name"
        },
        {
          key: "epsilon",
          label: "Epsilon",
          sortable: true,
          sortingKey: "epsilon"
        },
        {
          key: "changed",
          label: this.$t("COMMON.CHANGED"),
          formatter: Common.dateFormatter,
          sortable: true,
          sortingKey: "changed"
        },
        {
          key: "created",
          label: this.$t("COMMON.CREATED"),
          formatter: Common.dateFormatter,
          sortable: true,
          sortingKey: "created"
        }
      ],

      filterName: "",
      response: {
        data: [],
        totalCount: 0,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/api/oscillogram/powerline/paginated",
        requestType: "GET",
        requestParams: { 
          page: 0,
          size: 10,
          teamId: ApiService.teamData.value
        },
      },

      currentPage: 1,
      perPage: 10,

      showTableOverlay: true,
      calculate: false,
      selectedItem: null,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      powerlines: (state) => state.powerline.list,
      isLoading: (state) => state.powerline.isLoading,
      totalElements: (state) => state.powerline.totalElements
    }),
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.POWERLINE") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: () => this.selectedItem === null || this.showTableOverlay,
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: () => this.selectedItem === null || this.showTableOverlay,
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      }
    ]);
  },

  created() {
    this.getList.requestParams.page = 0;
    this.getList.requestParams.size = this.perPage;
    this.getList.requestParams.teamId = ApiService.teamData.value;
    this.loadList();
    this.updateActions();
  },

  methods: {
    loadList() {
      const params = {
        page: this.getList.requestParams.page,
        size: this.getList.requestParams.size,
        sort: this.getList.requestParams.sort,
        name: this.getList.requestParams.name,
        teamId: ApiService.teamData.value
      };

      this.showTableOverlay = true;
      this.$store
        .dispatch(POWERLINE_LIST_REQUEST, params)
        .then(() => {
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onPageSelected(index) {
      this.getList.requestParams.page = index - 1;
      this.loadList();
    },

    onRowSelected(items) {
      if (items && items.length > 0) {
        this.selectedItem = items[0];
      } else {
        this.selectedItem = null;
      }
    },

    updateActions() {
      const actions = [];
      if (this.selectedItem) {
        actions.push(
          {
            title: this.$t("DEVICE.EDIT_POWERLINE"),
            icon: "fa fa-edit",
            action: this.onEditAction,
          },
          {
            title: this.$t("COMMON.DELETE"),
            icon: "fa fa-trash",
            action: this.onDeleteAction,
          }
        );
      }
      actions.push({
        title: this.$t("COMMON.CREATE"),
        icon: "fa fa-plus",
        action: this.onCreateNewAction,
      });
      this.$store.dispatch(SET_ACTIONS, actions);
    },

    onCreateNewAction() {
      this.$router.push({ name: "powerline-create" });
    },

    onEditAction() {
      if (this.selectedItem) {
        this.$router.push({
          name: "powerline-edit",
          params: { id: this.selectedItem.id },
        });
      }
    },

    onDeleteAction() {
      if (!this.selectedItem) return;
      this.$bvModal.show('modal-delete-confirmation');
    },

    onDelete() {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, {
          resource: `/api/oscillogram/powerline/${this.selectedItem.id}/delete`,
          requestType: "DELETE",
        })
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
          this.selectedItem = null;
          this.getList.requestParams.page = 0;
          this.currentPage = 1;
          this.loadList();
        })
        .catch(this.onError)
        .finally(() => {
          this.showTableOverlay = false;
        });
    },

    onReloadAction() {
      this.getList.requestParams.page = 0;
      this.currentPage = 1;
      this.loadList();
    },

    showPerPageChanged(count) {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams.size = count;
      this.perPage = count;
      this.loadList();
    },

    onError(response) {
      if (response && response.config) response = response.data;
      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged(ctx) {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sort = `${field.sortingKey},${ctx.sortDesc ? 'desc' : 'asc'}`;
      }
      this.loadList();
    },

    onFilterSearch() {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams.name = this.filterName.length > 0 ? this.filterName : null;
      this.loadList();
    },

    onFiltersClear() {
      this.currentPage = 1;
      this.getList.requestParams.page = 0;
      this.getList.requestParams.size = this.perPage;
      delete this.getList.requestParams.name;
      this.filterName = "";
      this.loadList();
    },
  },
};
</script> 